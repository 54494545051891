<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入关键字(名称、电话、邮箱)"
        style="width: 300px"
        class="filter-item"
        clearable
      />

      <el-select
        v-model="listQuery.type"
        placeholder="类型"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in COLLECTOR_TYPE"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-input
        v-model="listQuery.country"
        placeholder="请输入国籍"
        style="width: 160px"
        class="filter-item"
        clearable
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="id" label="编号" width="60"> </el-table-column>
      <el-table-column prop="name" label="名字" width="80"> </el-table-column>
      <el-table-column prop="type" label="类型" width="80"></el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话"
        width="120"
      ></el-table-column>
      <el-table-column prop="country" label="国籍"></el-table-column>
      <el-table-column
        prop="email"
        label="电子邮件"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="address"
        label="联系地址"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="introduction"
        label="简称"
        width="480"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      title="新增"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择"
            style="width:100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in COLLECTOR_TYPE"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input type="number" v-model="form.phone" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="国籍" prop="country">
          <el-input v-model="form.country" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="电子邮件" prop="email">
          <el-input type="email" v-model="form.email" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="联系地址" prop="address">
          <el-input v-model="form.address" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="简介" prop="introduction">
          <el-input
            placeholder="请输入"
            type="textarea"
            v-model="form.introduction"
            :autosize="{ minRows: 2, maxRows: 8 }"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false, {})">取消</el-button>
        <el-button
          type="primary"
          @click="saveAddOrEditData"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { COLLECTOR_TYPE } from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import { collectorGetList, collectorCreate } from "@/api/art";

export default {
  computed: {
    COLLECTOR_TYPE() {
      return COLLECTOR_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        keyword: "", // 关键字
        type: "", // 类型
        country: "", // 国籍
        page: 1,
        limit: 10
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      rules: {
        type: [{ required: true, message: "类型不能为空", trigger: "change" }],
        name: [{ required: true, message: "姓名不能为空", trigger: "change" }],
        phone: [
          { required: true, message: "联系电话不能为空", trigger: "change" }
        ]
      },
      form: {
        id: 0, // 用作与修改
        name: "", // 姓名
        type: "", // 类型【0-个人，1-机构/组织】
        phone: "", // 联系电话
        country: "", // 国籍
        email: "", // 邮件
        address: "", // 联系地址
        introduction: "" // 简介
      },
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询收藏家列表
     */
    getList() {
      this.listLoading = true;
      collectorGetList(this.listQuery)
        .then(response => {
          this.list = (response.data && response.data.data) || [];
          this.total = response.data.total || 0;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      if (status) {
        this.form = {
          ...editObj
        };
      }
      if (!status) {
        this.form = {
          id: 0, // 用作与修改
          name: "", // 姓名
          type: "", // 类型【0-个人，1-机构/组织】
          phone: "", // 联系电话
          country: "", // 国籍
          email: "", // 邮件
          address: "", // 联系地址
          introduction: "" // 简介
        };
      }
      this.dialogFormVisible = status;
      this.$validator.reset();
    },
    /**
     * 确认添加还是编辑
     */
    saveAddOrEditData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            ...form
          };

          this.btnLoading = true;
          collectorCreate(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ {
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
